.formItem {
    .ant-modal-body {
        padding: 24px 5%;
    }
    .ant-form-item-label {
        white-space: normal;
        text-align: start;
    }
    .ant-form-item {
        margin-bottom: 12px !important;
    }
    .ant-modal-content {
        border-radius: 20px;
    }
   
    .ant-input[disabled] {
        color: #333;
       
    }
}

.line_full_width {
    border-bottom:1px solid #cccccc;
    margin-bottom: 27px;
    margin-top: 30px;
}
.inputNumberPhone {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
  }
  
  .inputNumberPhone:placeholder-shown {
    text-overflow: ellipsis;
  }
  .inputNumberPhone:hover {
    border-color: #00c4cc;
    border-right-width: 1px !important;
  }
  .inputNumberPhone:focus {
    border-color: #00c4cc;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow:0 0 0 2px #1890ff33;
  }
  .modalConfirm {
    
      .ant-modal-content {
        border-radius: 40px;
      }
      .ant-modal-body {
        padding: 50px;
      }
    
  }
