.date-input {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.form-search {
  display: flex;
}
.form-search > div {
  margin-right: 10px;
}
.status-name {
  text-align: center;
  font-size: 20px;
  padding: 10px 0;
}

.summary-top {
  flex-wrap: wrap;
}
ul.summary-top li {
  width: 200px;
  margin: 15px 25px;
}
ul.summary-select {
  justify-content: space-around;
}
.top-menu {
  flex-wrap: wrap;
  justify-content: space-around;
}

.top-menu li {
  width: 250px;
  height: 237px;
  margin-bottom: 20px;
  background: #00c4cc;
  border-radius: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-menu li > a {
  color: white;
}

.top-data ul li {
  position: relative;
}
.top-data ul li::before {
  content: "";
  width: 109%;
  height: 48px;
  border: 1px solid #ccc;
  border-top: none;
  display: block;
  position: absolute;
  bottom: -48px;
  right: 50%;
  display: none;
}
.top-data ul li:first-child::before {
  display: none;
}
@media screen and (max-width: 1023px) {
  .top-data ul li::before {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .form-search {
    flex-direction: column;
  }
  .form-search > * {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
}
