#root {
  height: 100%;
  /* background: #fff; */
  position: relative;
}

.signin-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 64px;
  transition: all ease-in-out 0.5s;
}

/* * {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, メイリオ, Meiryo,
    "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.chat-box {
  width: 50%;
  margin: auto;
}

.pointer {
  cursor: pointer;
}
.message-box {
  height: 80px;
}

.time {
  height: 0;
  transition: all ease-in-out 0.4s;
}
.has-time:hover .time {
  height: 25px;
}

@media screen and (max-width: 767px) {
  .signin-box {
    width: 90%;
    padding: 40px;
  }
  .chat-box {
    width: 100%;
  }
}
