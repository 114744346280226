
.ant-modal-body {
     padding: 0px;
}
.ant-modal-footer {
     border-top: unset;
}
.ant-modal-header {
     border-top: unset;
}
.btn-modal-wrapper {
     padding-top: 0px;
}

.delete-manager-list li {
     padding: 15px;
}
.ant-modal-header {
     border-bottom: unset;
}
.ant-input-lg{
     font-size: 14px !important;
}
.ant-input-affix-wrapper-lg{
     font-size: 14px !important;
}
@media (min-width: 360px) {
     .ant-table {
          width: 100%;
          overflow-x: auto;

          &-thead > tr,
          &-tbody > tr {
               > th,
               > td {
                    white-space: pre;

                    > span {
                         display: block;
                    }
               }
          }
     }
}
.required {
     background: #fe7171;
     color: #ffffff;
     font-size: 13px;
     font-weight: 500;
     // height: 18px;
     width: 36px;
     border-radius: 2px;
     padding: 1px 5px !important;
}
.required_admin {
     background: #FFF1F0;
     color: #E75B53;
     font-size: 13px;
     font-weight: 500;
     // height: 18px;
     width: 36px;
     border-radius: 2px;
     padding: 1px 5px !important;
}

.menuChilItems {
     /* border-bottom: 1px solid #ccc; */
     max-height: 0;
     overflow: hidden;
     transition: max-height 0.3s ease-out;
   }
   
   .menuChilItems.menuItemActive {
     max-height: 10000px;
     // overflow-y: auto;
     transition: max-height 0.3s ease-in;
   }
   
   .dialog{
        z-index: 1100;
   }


   .fixNavbar{
     .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after {
          border-bottom: none !important;
     }
     
   }.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
     color: #fcdf80 !important;
}

#google_map >iframe {
     width: 299px;
}

.styleCardInSearch {
    
       .ant-card-body {
         padding-bottom: 0;
       }
       .ant-form-item-label > label::after {
         content: '';
       }
     
   }

   .wrapRow{
     display: block;
     display: -webkit-box;
      -webkit-line-clamp: 1;  /* số dòng hiển thị */
      -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
     margin-bottom: 0px;
   }

   .styleButtonHOC{
     color: #fff !important;
     cursor: pointer !important;
     background: #00c4cc ;
     border: none !important;
     border-radius: 28px !important;
     padding: 8px 40px !important;
     font-size: 16px !important;
     font-weight: 700 !important;
     height: unset !important;
   }
   .ant-picker-input > input[disabled]{
     color: black !important;
   }
