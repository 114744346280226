.box-status {
  background: white;
  padding: 40px;
  border-radius: 16px;
}

.company-ttl-item {
  justify-content: space-between;
}

.ant-message-notice-content{
  box-shadow: none !important;
 border-radius: 30px !important;
  opacity: "0.9" !important;
  color: "#43766c" !important;
  min-width: "200px" !important;
  padding: 0px;
}

