.ant-modal-content{
  top: 0px !important;
}

.noti-modal{
  letter-spacing: .04em;
  color: #0abab5;
  margin-bottom: 10px;
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
}

.noti-button-send{
  color: #fff !important;
  cursor: pointer !important;
  background: #00c4cc !important;
  border: none !important;
  border-radius: 28px !important;
  padding: 8px 40px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  height: unset !important;
  margin-top: 20px!important;
  
}
