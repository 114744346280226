   
.style-interView{
     .ant-form-item-label > label {
          margin-right: 45px;
 }
}


.formItem {
     .ant-modal-body {
         padding: 24px 5%;
     }
     .ant-form-item-label {
         white-space: normal;
         text-align: start;
     }
     .ant-form-item {
         margin-bottom: 12px !important;
     }
     .ant-modal-content {
         border-radius: 20px;
     }
    
     .ant-input[disabled] {
         color: #333;
        
     }
 }
