.submitButton {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  background-color: #00c4cc!important;
  border: none !important;
}
.forgotPassword {
  color: #00c4cc!important;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.alertMessage {
  padding: 8px;
  color: red;
  background-color: '#F6EBEB';
  border-radius: 5px;
}

.title {
  display: flex;
  justify-content: center;
  color: #00c4cc!important;
  font-size: 32px;
  line-height: 30px;
  letter-spacing: 0.01em;
}