.ant-select{
  width: 100%;
}
.ant-message-notice-content{
  box-shadow: none;
}
.inputText::after {
  background: red;
}
.checkbox-alig {
  display: flex;
  align-items: center;
}

textarea {
  height: 120px;
}

.text-bold {
  font-weight: bold;
  color: #555;
}

.flex {
  display: flex;
}

.text-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.04em;
  margin-right: 21px;
  min-width: 70px;
  /* margin-bottom: 10px; */
}
.btn-blue-medium {
  color: #fff;
  cursor: pointer;
  background: #00c4cc;
  border: none;
  border-radius: 28px;
  padding: 8px 60px;
  font-size: 16px;
  font-weight: bold;
}
.btn-chat {
  color: white;
  background: #00c4cc;
  font-weight: 700;
  border-radius: 4px;
  padding: 0 8px;
  margin-left: 30px;
}
.link {
  color: #00c4cc;
  font-weight: 500;
  margin: 0 20px;
}

.wrapper-gray {
  background: #eee;
  padding-bottom: 160px;
}
.select.input-field,
.input-field {
  width: 340px;
  height: 40px;
}

.results-flex-item ul {
  width: unset;
}
.results-flex-item {
  flex-wrap: wrap;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.inner-results {
  max-width: unset;
  margin: 0;
}

.results-item {
  max-width: unset;
}

.title-box {
  color: #555;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  padding-bottom: 20px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}

.btn-set-schedule {
  width: 270px;
}
.results-list li dl dt {
  align-items: center;
}

.text-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.04em;
  color: #00c4cc;
}
.message-box {
  height: 40px;
}

.time {
  height: 0;
  transition: all ease-in-out 0.4s;
}
.has-time:active .time {
  height: 25px;
}

.form-search-top {
  display: grid;
  grid-template-columns: 100px auto;
  gap: 20px;
  margin-bottom: 30px;
}

.form-search-bot {
  display: grid;
  grid-template-columns: 100px auto;
  gap: 20px;
  margin-bottom: 30px;
}
.form-search-top-inner {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

.form-search-bot-inner {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

input {
  background: #fff8e5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 16px;
}
.form-search-bot-inner input,
.form-search-top-inner input {
  width: 100%;
}

.applicant-info {
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-info {
  width: 250px;
}

.status-info {
  min-width: 170px;
  display: flex;
  align-items: center;
}

.chedule-info {
  min-width: 85px;
  cursor: pointer;
  justify-content: right;
  align-items: center;
  display: flex;
}
.memo-info {
  min-width: 150px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  max-width: 200px;
  height: 100%;
  overflow: auto;
}

.list-table-memo {
  position: relative;
  padding: 0 35px 0 20px !important;
}

.icon-edit {
  position: absolute;
  right: 15px;
}

.result-description {
  min-width: 150px;
}
.result-status-name {
  min-width: 120px;
}
.result-delete,
.result-grade {
  min-width: 60px;
}

.box-applicant-info {
  display: flex;
  min-width: 280px;
  justify-content: space-between;
}
.ant-input[disabled] {
  color: black;
 
}
@media screen and (max-width: 1024px) {
  .results-flex-btn {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .form-search-top-inner {
    grid-template-columns: auto auto;
  }
  .form-search-top-inner > input:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
  }
  .form-search-top-inner > input:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
  .form-search-bot-inner {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 768px) {
  .results-flex-item ul {
    width: 100%;
  }
  .select.input-field,
  .input-field {
    width: 100%;
  }
  .results-list li dl {
    flex-wrap: wrap;
  }
  .results-list li dl dt {
    margin-bottom: 15px;
  }
  .results-list li dl dd {
    width: 100%;
  }
  .select-item-w340 {
    max-width: unset;
  }
  .form-search-top,
  .form-search-bot {
    grid-template-columns: auto;
  }
  .form-search-top-inner > input:nth-child(3) {
    grid-column: 1;
    grid-row: 3;
  }
  .form-search-top-inner > input:nth-child(2) {
    grid-column: 1;
    grid-row: 4;
  }
  .form-search-bot-inner,
  .form-search-top-inner {
    grid-template-columns: auto;
  }
  
}



