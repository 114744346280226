
    .style_button{
     .ant-btn {
          color: #fff !important;
          cursor: pointer !important;
          background: #00c4cc !important;
          border: none !important;
          border-radius: 28px !important;
          padding: 8px 40px !important;
          font-size: 16px !important;
          font-weight: 700 !important;
          height: unset !important;
     }
    }
   