.company-item {
  padding: 40px 20px;

  margin: 0;
  max-width: unset;
  display: block;
  background: white;
  border-radius: 16px;
}

td.company-name {
  min-width: 150px;
}

.box-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
}

.btn-right {
  background: #00c4cc;
  border-radius: 4px;
  padding: 0 5px;
}

.btn-right a {
  color: white;
}

.vertical-line {
  width: 1px;
  height: 20px;
  background: #ccc;
  margin: 0 15px;
}
