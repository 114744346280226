
    .style_modal{
     .ant-modal-header {
          border-radius: 30px;
      }
      .ant-modal-content{
          border-radius: 30px;
      }
 
    }
   