html {
  scroll-behavior: smooth;
}

.text-header {
  font-weight: 500;
  font-size: 16px;

  color: white;
}

.user-name {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: white;
}

.has-arrow-down {
  padding: 20px;
  position: relative;
}

.pointer {
  cursor: pointer;
}

.has-arrow-down::after {
  vertical-align: 0.255em;
  content: "";
  border: 0.3em solid transparent;
  border-top-color: white;
  border-bottom: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.block-padding {
  padding: 10px;
}

.th-sort {
  cursor: pointer;
}

.link-hover:hover .text-header {
  color: #fcdf80;
}

.link-hover .text-header,
.btn-hover-yl,
.link-hover-yl {

  transition: all 0.3s ease-in-out;
}

.link-hover-yl-table {
  color: #00c4cc;
  transition: all 0.3s ease-in-out;
}

.link-hover-yl-table:hover {
  color: #fcdf80;
}


.form-search-mb {
  display: none;
}

.form-search-inner {
  display: flex;
  align-items: center;
}

.form-search-inner input {
  width: 200px;
}

.form-search-inner>*:not(input) {
  padding: 0.75rem;
  background: #00c4cc;
  color: white;
  border-radius: 4px;
}

.btn-hover-yl:hover,
.link-hover-yl:hover {
  color: #fcdf80;
}

.has-arrow {
  position: relative;
}

.has-arrow select {
  width: 100%;
  background: #fff8e5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
}

.has-shadow-top {
  box-shadow: 1 0 8px -2px #ccc;
}

.has-shadow-bot {
  box-shadow: -1 0 8px -2px #ccc;
}

.has-shadow {
  box-shadow: 0 0 8px -2px #ccc;
}

.has-arrow::before {
  content: "";
  width: 0;
  border-bottom: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 10px;
  right: 9px;
}

.has-arrow::after {
  content: "";
  width: 0;
  border-top: 4.5px solid #555;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  top: 21px;
  right: 9px;
}

.mnw-100 {
  min-width: 100px;
}

.mnw-170 {
  min-width: 170px;
}

.process-bot {
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.process-bot-btn {
  display: flex;
  padding: 16px 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px 0;
}

.btn-submit-modal,
.btn-exit-modal {
  border: 2px solid #00c4cc;
  border-radius: 100vh;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.btn-exit-modal {
  color: #00c4cc;
}

.btn-submit-modal {
  color: white;
  background: #00c4cc;
}

.btn-modal-wrapper {
  padding-top: 20px;
  text-align: right;
}

.btn-modal-wrapper .btn-exit-modal {
  margin-right: 16px;
}

.has-table {
  max-height: 600px;
  overflow: auto;
  border: 1px solid #eee;
}

.head-table {
  position: sticky;
  top: 0;
  z-index: 5;
}

table {
  border-collapse: separate;
  /* Don't collapse */
}

.has-sort {
  margin-left: 8px;
}

.has-sort.up::before,
.has-sort.normal::before {
  content: "";
  position: absolute;
  top: 5px;
  width: 0;
  border-bottom: 4.5px solid #555;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.has-sort.down::after,
.has-sort.normal::after {
  content: "";
  position: absolute;
  top: 14px;
  width: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4.5px solid #555;
}

.MuiOutlinedInput-input {
  padding: 10px !important;
}

.MuiFormControl-root {
  background: #fff8e5;
  height: 40px;
}

.MuiOutlinedInput-root {
  height: 40px !important;
}

.MuiOutlinedInput-root>input {
  height: 100% !important;
  padding: 0 10px !important;
  border-radius: 4px;
  border: 1px solid #fff8e5;
}

.summary-count {
  display: grid;
  grid-template-columns: 20% auto auto auto auto;
}

.summary-count>* {
  width: 100%;
  text-align: center;
}

select {
  height: 43px;
}

.has-long-text {
  max-width: 200px;
  overflow: auto;
  max-height: 200px;
}

@media screen and (max-width: 550px) {
  .summary-count {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .block-padding {
    display: none;
  }

  .user-name {
    display: none;
  }

  .has-arrow-down {
    display: none;
  }

  .header-search {
    display: none;
  }

  .form-search-mb {
    display: block;
    padding: 10px 1rem;
  }
}